<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="标题" prop="title">
        <a-input v-model="form.title" placeholder="请输入标题" />
      </a-form-model-item>
      <a-form-item label="类型">
        <a-select v-model="form.type" placeholder="请选择数据类型" prop="type">

          <a-select-option value="0">
            引导页
          </a-select-option>
          <a-select-option value="1">
            启动页
          </a-select-option>

        </a-select>
      </a-form-item>
      <a-form-item label="引导页推荐页数据类型">
        <a-select v-model="form.guideType" placeholder="引导页推荐页数据类型" prop="guideType">
          <a-select-option :value="0">
            图片
          </a-select-option>
          <a-select-option :value="1">
            视频
          </a-select-option>
        </a-select>
      </a-form-item>

      <!-- <a-form-model-item label="图片地址" prop="imgUrl" >
        <a-input v-model="form.imgUrl" placeholder="请输入图片地址" />
      </a-form-model-item> -->

      <a-form-model-item label="图片" prop="imgUrl" v-if="form.guideType == 0">
        <template>
          <div class="clearfix">
            <a-upload action="http://192.168.1.140:7060/admin-api/common/local/file/upload" list-type="picture-card"
              :file-list="fileList" @preview="handlePreview" @change="handleChange">
              <div v-if="fileList.length < 1">
                <a-icon type="plus" />
                <div class="ant-upload-text">
                  上传图片
                </div>
              </div>
            </a-upload>
            <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
              <img alt="example" style="width: 100%" :src="form.imgUrl" />
            </a-modal>
          </div>
        </template>
      </a-form-model-item>
      <!-- <a-form-model-item label="视频地址" prop="vedioUrl" v-if="form.guideType == 1">
        <a-input v-model="form.vedioUrl" placeholder="请输入视频地址" />
      </a-form-model-item> -->
      <a-form-model-item label="视频(第一个有效)" prop="vedioUrl" v-if="form.guideType == 1">
        <template>
          <div>
            <a-upload list-type="picture" action="http://47.245.94.137:7070/admin-api/common/upload"
              :preview-file="previewFile" @change="handleChangeVideo">
              <a-button> <a-icon type="upload" /> 上传视频 </a-button>
            </a-upload>
          </div>
        </template>
      </a-form-model-item>
      <a-form-model-item label="轮播时间(秒)" prop="time">
        <a-input v-model="form.time" placeholder="请输入轮播时间(秒)" />
      </a-form-model-item>
      <a-form-model-item label="描述" prop="description">
        <a-input v-model="form.description" placeholder="请输入描述" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getGuidePage, addGuidePage, updateGuidePage } from '@/api/door/guidePage'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data() {
    return {
      previewVisible: false,
      submitLoading: false,
      formTitle: '',
      fileList: [
      ],
      videoList:[],
      // 表单参数
      form: {
        id: null,


        title: null,

        type: null,

        guideType: null,

        description: null,

        imgUrl: null,

        vedioUrl: null,

        time: null,

        sort: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        title: [
          { required: true, message: '标题不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created() {
  },
  computed: {
  },
  watch: {
  },
  mounted() {
  },
  methods: {
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        id: null,
        title: null,
        type: null,
        guideType: null,
        description: null,
        imgUrl: null,
        vedioUrl: null,
        time: null,
        sort: null,
        delFlag: null,
        createTime: null,
        createBy: null,
        updateBy: null
      }
      // this.$nextTick(() => {
      //   this.$refs.form.resetFields()
      // })
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getGuidePage({ "id": id }).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
        this.fileList[0] = {
          uid: '-1',
          name: 'image.png',
          status: 'done',
          url: response.data.imgUrl
        }
        this.videoList[0] = {
          uid: '-1',
          name: 'video.mp4',
          status: 'done',
          url: response.data.vedioUrl
        }
      })
    },
    handleCancel() {
      this.previewVisible = false;
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    handleChange(info) {
      console.log(info)
      this.fileList = info.fileList
      this.fileList.forEach(item => {
        if (item.status === 'done') {
          if (item.response.resultCode !== 1) {
            item.status = 'error'
            item.url = undefined
          } else {
            item.url = `${this.url_}${item.response.data}`
          }
          this.form.imgUrl=item.response.data
        }
      })
   console.log(this.form.imgUrl)
    //   this.fileList = fileList;
    //   this.fileList.forEach(element => {
    //     console.log("文件上传" + element.response)
    //     this.form.imgUrl=element.url
    //   });
     },
     handleChangeVideo(info){
      this.videoList = info.fileList
      this.videoList.forEach(item => {
        if (item.status === 'done') {
          if (item.response.resultCode !== 1) {
            item.status = 'error'
            item.url = undefined
          } else {
            item.url = `${this.url_}${item.response.data}`
          }
          this.form.vedioUrl=item.response.data
        }
      })
      },
    async previewFile(file) {
      console.log('Your upload file:', file);
      // Your process logic. Here we just mock to the same file
      const res = await fetch('http://192.168.1.75:7060/admin-api/common/local/file/upload', {
        method: 'POST',
        body: file,
      })
      res.json()
      console.log(res)
    },

    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateGuidePage(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addGuidePage(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
